import { defineStore } from 'pinia'

import _ from 'lodash'

import moment from 'moment'
import api from '../../api/api'
import store from '..'
import { CurrentTariif, MarketplaceIntegration } from '../../types/marketplace'

export const useBillingStore = defineStore('billng', {
    state: () => ({
        tariffs: [] as MarketplaceIntegration[],
        selectedTariffId: null as string | null,
        selectedSubscriptionPeriod: null as string | null,
        currentTariff: {} as {
            [projectId: string]: CurrentTariif
        },
        isTrialActivatedBefore: false as boolean,
        paymentModal: {
            opened: false as boolean,
        },
    }),
    actions: {
        async fetchCurrentTariff(projectId: string) {
            const { data, error } = await api.marketplace.getCurrentTariff(projectId)
            if (error) {
                store.dispatch('callNotify', 'Произошла ошибка при загрузке текущего тарифа')
                return
            }
            this.currentTariff = Object.assign({}, this.currentTariff, { [projectId]: data?.currentTariff })
            this.isTrialActivatedBefore = data?.isTrialActivatedBefore
        },
        async fetchTariffs() {
            const { data, error } = await api.marketplace.getAllTariffs()
            if (error) {
                store.dispatch('callNotify', 'Произошла ошибка при загрузке тарифов')
                return
            }
            this.tariffs = data!
        },
        formatPrice(price: number) {
            return `${new Intl.NumberFormat('ru-RU').format(price)} ₽`
        },
        setPaymentModalOpened(value: boolean) {
            this.paymentModal.opened = value
        },
        async unsubscribeIntegration(projectId: string, integrationId: string) {
            const { data, error } = await api.marketplace.unsubscribeIntegration({
                projectId,
                marketplaceIntegrationId: integrationId,
            })
            if (error) {
                store.dispatch('callNotify', 'Произошла ошибка при отмене подписки')
                return
            }
            store.dispatch('callNotify', 'Подписка успешно отменена')
            await this.fetchCurrentTariff(projectId)
        },
    },
    getters: {
        getCurrentTariff(state) {
            return (projectId: string): CurrentTariif => {
                const tariff = state.currentTariff
                if (!tariff[projectId]) return undefined
                return tariff[projectId]
            }
        },
        getIsTrialActivatedBefore(state) {
            return () => state.isTrialActivatedBefore || false
        },
        getTariffById(state) {
            return (tariffId: string): MarketplaceIntegration | undefined =>
                state.tariffs.find(tariff => tariff.id === tariffId)
        },
        tariffIds: state => state.tariffs.map(el => el.id),
        getCurrentTariffState: state => (projectId: string) => {
            const currentTariff = state.currentTariff[projectId]
            if (!currentTariff) return ''
            if (moment(currentTariff.activeUntil).isBefore(moment())) {
                return `Приостановлен`
            }
            if (currentTariff.activeUntil && currentTariff.isTrial) {
                const formattedDate = new Date(currentTariff.activeUntil).toLocaleDateString()
                return `Активен до ${formattedDate} (пробный период)`
            }
            if (currentTariff.activeUntil) {
                const formattedDate = new Date(currentTariff.activeUntil).toLocaleDateString()
                return `Активен до ${formattedDate}`
            }
        },
        isCurrentTariffActive: state => (projectId: string) => {
            const currentTariff = state.currentTariff[projectId]
            if (!currentTariff) return false
            if (moment(currentTariff.activeUntil).isBefore(moment())) {
                return false
            }
            return true
        },
        getTarrifRegularPrice: state => (tariffId: string, subscriptionPeriod: string | undefined) => {
            if (!subscriptionPeriod) return 0
            const tariff = state.tariffs.find(tariff => tariff.id === tariffId)
            if (!tariff) return 0
            return tariff.complexPrice.monthPeriod[subscriptionPeriod].regularPayment.sum
        },
    },
})
