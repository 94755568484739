import { defineStore } from 'pinia'

import _ from 'lodash'

const DEFAULT_VALUES = {
    title: 'ВНИМАНИЕ!',
    type: 'text',
    text: '',
    color: 'main',
}
interface OpenModalParams {
    title?: string
    type?: string
    text?: string
    color?: string
}
export const useModalStore = defineStore('modal', {
    state: () => ({
        active: false as Boolean,
        title: DEFAULT_VALUES.title,
        type: DEFAULT_VALUES.type,
        text: DEFAULT_VALUES.text as string,
        color: DEFAULT_VALUES.color,
    }),
    actions: {
        clearState() {
            this.active = false
            setTimeout(() => {
                this.title = DEFAULT_VALUES.title
                this.type = DEFAULT_VALUES.type
                this.text = DEFAULT_VALUES.text
                this.color = DEFAULT_VALUES.color
            }, 150)
        },
        open(parmas: OpenModalParams) {
            this.title = parmas.title || DEFAULT_VALUES.title
            this.type = parmas.type || DEFAULT_VALUES.type
            this.text = parmas.text || DEFAULT_VALUES.text
            this.color = parmas.color || DEFAULT_VALUES.color
            this.active = true
        },
        close() {
            this.clearState()
        },
    },
})
