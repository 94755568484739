import { render, staticRenderFns } from "./TextStep.vue?vue&type=template&id=b1ff0d92&scoped=true"
import script from "./TextStep.vue?vue&type=script&lang=ts"
export * from "./TextStep.vue?vue&type=script&lang=ts"
import style0 from "./TextStep.vue?vue&type=style&index=0&id=b1ff0d92&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b1ff0d92",
  null
  
)

export default component.exports