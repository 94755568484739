
import { PropType, computed, defineComponent, watch, ref, onBeforeMount, onMounted } from 'vue'
import * as _ from 'lodash'
import useRouter from '../../../../../hooks/useRouter'
import { useProject } from '../../../../../hooks/useProject'
import { CONNECTOR_TYPES } from '../../../../../vars/general'
import api from '../../../../../api/api'
import CustomActivatorSelect from '../../../../inputs/CustomActivatorSelect.vue'
import BaseInputOutlined from '../../../../inputs/BaseInputOutlined.vue'
import TextInputGroup from '../../../../ui/TextInputGroup.vue'
export default defineComponent({
    props: {
        value: {
            type: Object,
            required: true,
        },
        app: {
            type: Object,
            required: true,
        },
    },
    components: { TextInputGroup, CustomActivatorSelect, BaseInputOutlined },
    setup(props, { emit }) {
        const { route } = useRouter()
        const trigger = computed(() => route.value.query.id)
        const { project } = useProject()
        const activeTab = ref(0)
        const staff = ref([])

        const hasYcConnector = computed(() => {
            return project.value.connectors.some(
                connector => connector.connectorType === CONNECTOR_TYPES.yClients
            )
        })
        const tabs = [
            {
                tabName: 'Только для этого тригерра',
                key: 'trigger',
            },
            {
                tabName: 'Для всех тригерров',
                key: 'app',
            },
        ]
        const activeTabKey = computed(() => tabs[activeTab.value]?.key)

        const getStaffBanlist = () => {
            if (activeTabKey.value === 'app') {
                return props.app.banOnStaff
            }
            if (activeTabKey.value === 'trigger') {
                return props.value.banOnStaff
            }
            return []
        }
        const updateStaffBanlist = (v: any[]) => {
            if (activeTabKey.value === 'app') {
                emit('update-app', Object.assign({}, props.app, { banOnStaff: v }))
            }
            if (activeTabKey.value === 'trigger') {
                emit('input', Object.assign({}, props.value, { banOnStaff: v }))
            }
        }
        const getClientBanlist = () => {
            if (activeTabKey.value === 'app') {
                return props.app.banOnClient || []
            }
            if (activeTabKey.value === 'trigger') {
                return props.value.banOnClient || []
            }
            return []
        }
        const updateClientBanlist = (v: any[]) => {
            if (activeTabKey.value === 'app') {
                emit('update-app', Object.assign({}, props.app, { banOnClient: v }))
            }
            if (activeTabKey.value === 'trigger') {
                emit('input', Object.assign({}, props.value, { banOnClient: v }))
            }
        }

        const onNumberInputChangeTransform = (v: string) => v.replace(/[^0-9+]/g, '')
        onMounted(async () => {
            if (hasYcConnector.value) {
                staff.value = await api.connectors.yClients
                    .getStaffList(project.value.id)
                    .then(res => res.data!)
            }
        })
        return {
            trigger,
            getStaffBanlist,
            tabs,
            updateStaffBanlist,
            activeTab,
            staff,
            hasYcConnector,
            getClientBanlist,
            updateClientBanlist,
            onNumberInputChangeTransform,
        }
    },
})
